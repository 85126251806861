.rbc-month-view {
  border-radius: 8px; /* Adjust the radius as needed */
  flex: 1;
}
.rbc-month-header {
  background-color: transparent;
}
.rbc-time-view {
  border-radius: 8px; /* Adjust the radius as needed */
  overflow: hidden;
}
.rbc-row-content {
  background-color: transparent !important;
}
.rbc-row {
  background-color: transparent !important;
}
/* only for mobile */

@media (max-width: 768px) {
  .rbc-date-cell {
    text-align: center;
  }
}
.rbc-show-more {
  color: black;
  font-size: 12px !important;
  text-decoration-line: underline;
  border-width: 0px;
}
.rbc-event {
  overflow: hidden;
  flex: 1;
}

.rbc-event-content {
  font-size: 12px !important;
}
